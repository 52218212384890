<div class="dialog modal-reject">
  <div class="icon-container mb-3">
    <i class="pi pi-times"></i>
  </div>

  <div class="text-container">
    <h4>{{ title }}</h4>
    <p>{{ text }}</p>
  </div>

  <!-- Botón Opcional -->
  <button
    *ngIf="showButton"
    class="btn-ver-solicitudes"
    (click)="onButtonClick()"
  >
    {{ buttonText }}
  </button>
</div>
