import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    return value
      .replace(/\bS\.A\.?(?:C\.?)?\b/gi, '')
      .replace(/\s{2,}/g, ' ')
      .trim()
      .replace(/\.$/, '')
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
