import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IComboBase } from '../../interfaces/base/base.interface';

@Component({
  selector: 'app-page-acciones',
  templateUrl: './page-acciones.component.html',
  styleUrls: ['./page-acciones.component.scss'],
})
export class PageAccionesComponent {
  // Configuración para el botón
  @Input() buttonText: string = '';
  @Input() buttonIcon: string = '';
  @Input() buttonColor: string = '';
  @Input() textButtonColor: string = 'white';
  @Output() buttonClick = new EventEmitter<void>();
  @Input() hasButton: boolean = false;

  // Configuración para el botón 2
  @Input() buttonTextSecond: string = '';
  @Input() buttonIconSecond: string = '';
  @Input() buttonColorSecond: string = '';
  @Input() textSecondButtonColor: string = 'white';
  @Output() buttonClickSecond = new EventEmitter<void>();
  @Input() hasButtonSecond: boolean = false;

  // Configuración para el combo
  @Input() comboOptions: any[] = []; // Lista de opciones del combo
  @Input() comboSelected: any; // Valor seleccionado
  @Input() comboPlaceholder: string = 'Seleccione una opción'; // Placeholder del combo
  @Output() comboSelectedChange = new EventEmitter<any>(); // Evento de cambio en el combo

  // Configuración para la barra de búsqueda
  @Input() searchPlaceholder: string = 'Buscar...'; // Placeholder de búsqueda
  @Input() searchQuery: string = ''; // Consulta actual
  @Output() searchQueryChange = new EventEmitter<string>(); // Evento de cambio en la búsqueda

  // Métodos para manejar las acciones
  onButtonClick(): void {
    this.buttonClick.emit();
  }

  // Métodos para manejar las acciones del segundo botón
  onSecondButtonClick(): void {
    this.buttonClickSecond.emit();
  }
  
  onComboChange(value: IComboBase): void {
    this.comboSelected = value;
    this.comboSelectedChange.emit(value); // Emitir evento de cambio en el combo
  }

  onSearchInput(query: string): void {
    this.searchQuery = query;
    this.searchQueryChange.emit(query); // Emitir evento de cambio en la búsqueda
  }
}
