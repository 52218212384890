import { Component, ViewEncapsulation } from '@angular/core';

import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-modal-loading',
	templateUrl: './modal-loading.component.html',
	styleUrls: ['./modal-loading.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class ModalLoadingComponent {
	constructor(public ref: DynamicDialogRef) {}

}
