import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {
  @Input() title: string = '';     // Propiedad para el título
  @Input() subtitle: string = '';  // Propiedad para el subtítulo
  @Input() subtitle2: any = '';  
}
